import React, { useState } from 'react';

import styles from './app.module.scss';
import { Main } from './main';

export const App: React.FC = () => {
  const [isGarlandVisible] = useState (() => {
    const today = new Date ();
    return today.getMonth () > 10 || today.getMonth () < 1;
  })

  return <div className={styles.wrapper}>
    {
      isGarlandVisible &&
      <ul className={styles.garland_wrapper}>
        {new Array (42).fill (<li />)}
      </ul>
    }

    <Main />
  </div>
}