import React, { useEffect, useState } from 'react';
import { randomInteger } from '@wigard/util.js';

import styles from './index.module.scss';
import { ReactComponent as Github } from '../assets/img/github.svg';
import { ReactComponent as VK } from '../assets/img/vk.svg';
import { ReactComponent as Spotify } from '../assets/img/spotify.svg';

export const Main: React.FC = () => {
  const [translate, setTranslate] = useState ([0, 0]);
  const [flashing, setFlashing] = useState (false);

  useEffect (() => {
    const translateInt = setInterval (() => setTranslate ([randomInteger (0, 50) / 10, randomInteger (0, 50) / 10]), 100);
    const flashingInt = setInterval (() => setFlashing (flashing => !flashing), 500);

    return () => {
      clearInterval (translateInt);
      clearInterval (flashingInt);
    }
  }, []);
  
  return <div className={styles.wrapper}>
    <div
      className={styles.text_wrapper}
      style={{
        transform: `translate(${translate[0]}px, ${translate[1]}px)`,
        textShadow: flashing ? '' : 'none'
      }}
    >
      welcome to

      <div className={styles.main_wrapper}>
        wigard
      </div>

      page
    </div>

    <div className={styles.footer_wrapper}>
      <a href='https://github.com/wigarddev' target='_blank' rel='noopener noreferrer'>
        <Github />
      </a>

      <a href='https://vk.com/id464511001' target='_blank' rel='noopener noreferrer'>
        <VK />
      </a>

      <a href='https://open.spotify.com/user/l2jsj346fvxovmq7us8c1m41p' target='_blank' rel='noopener noreferrer'>
        <Spotify />
      </a>
    </div>
  </div>
}